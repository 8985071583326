import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Search from "./components/Search";
//import Playlist from "./components/Playlist";
import Results from "./components/Results";
import Video from "./components/Video";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Search />
        </Route>
        <Route exact path="/video/:type/:id" component={Video} />
        <Route exact path="/video/:type/:id/s:season" component={Video} />
        <Route exact path="/video/:type/:id/s:season/e:episode" component={Video} />

        <Route path="/results">
          <Results data={[]} />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
