import React from "react";

const Results = ({ data }) => {
  return (
    <div>
      {data.map((item) => (
        <div key={item.id}>
          <img src={item.poster} alt={item.title} />
          <h2>{item.title}</h2>
        </div>
      ))}
    </div>
  );
};

export default Results;

