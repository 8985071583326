import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';

const Search = () => {
  const search = document.getElementById('search');

  const [text, setText] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    if (text.length >= 3) {
      const newTimeout = setTimeout(() => {

      axios
        .get(`https://kino.uwwu.ru/search/`+text)
        .then((response) => {
          setSuggestions(response.data);
        })
        .catch((error) => {
          console.log(error);
        })}, 500);

      setDebounceTimeout(newTimeout);
    } else {
      setSuggestions([]);
    }
  }, [text]);

  const handleInputChange = (event) => {
    console.log("input value:", event.target.value);
    setText(event.target.value);
    // setSuggestions([]);
  };

  const handleKeyDown = (event) => {
  console.log("key pressed:", event.key);
  if (event.key === "Enter" || event.keyCode === 13) {
    axios
      .get(`https://kino.uwwu.ru/full-search/`+text)
      .then((response) => {
        setSuggestions(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  };

  return (<div>
      <input
        type="text"
        value={text}
        placeholder="Search films..."
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      <div className="posters">
        {suggestions.map((item) => (
          <div className="poster" key={item.id}><Link to={"/video/"+item.type+"/"+item.id}><img src={"https://kino.uwwu.ru/poster/item/small/" + item.id + ".jpg"} alt={"/video/"+item.type+"/"+item.id}></img><h2>{item.value}</h2></Link></div>
          // <Link to={"/video/"+item.type+"/"+item.id}>{item.value}</Link>
        ))}
      </div>
    </div>
  );
};

export default Search;

