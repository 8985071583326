import React, { useRef, useEffect, useState } from 'react';
import axios from "axios";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-quality-levels';
import styles from './styles.css';
import HlsQualitySelector from 'videojs-hls-quality-selector';
// import 'videojs-resolution-switcher/lib/videojs-resolution-switcher.css';
// import videojsResolutionSwitcher from 'videojs-resolution-switcher';



const Video = ({ match }) => {
  const videoRef = useRef();
  const [suggestions, setSuggestions] = useState(1);
  const playerRef = useRef(null);
  const [poster, setPoster] = useState("");
  const [url, setUrl] = useState("");
  const [season, setSeason] = useState(match.params.season || 1);
  const [episode, setEpisode] = useState(match.params.episode || 1);
  const [episodes, setEpisodes] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let apiUrl;
    if (match.params.type === "serial" || match.params.type === "docuserial" || match.params.type === "tvshow") {
      apiUrl = `https://kino.uwwu.ru/get/${match.params.type}/${match.params.id}/s${season}e${episode}`;
    } else {
      apiUrl = `https://kino.uwwu.ru/get/${match.params.type}/${match.params.id}`;
    }
    setLoading(true);
    axios
      .get(apiUrl)
      .then((response) => {
        setLoading(false);
        setPoster("https://kino.uwwu.ru/poster/item/big/" + match.params.id + ".jpg")
        setUrl(response.data.url);
        console.log("log "+response.data.url)
        setSuggestions(response.data['count_seasons']);
        setEpisodes(response.data['count_episodes']);

        videojs.registerPlugin('hlsQualitySelector', HlsQualitySelector);
        // videojs.registerPlugin('videoJsResolutionSwitcher', videojsResolutionSwitcher);

        playerRef.current = videojs(videoRef.current, {

          sources: [
            {
              src: response.data.url,
              type: 'application/x-mpegURL',
            },
          ],
          plugins: {
            qualityLevels: {},
            hlsQualitySelector: {},

          },
        });

        // Load saved playback time from local storage
        const savedTime = localStorage.getItem(`video-save-point-${match.params.id}-${season}-${episode}`);
        if (savedTime) {
          playerRef.current.currentTime(savedTime);
        }
        // Add event listener to save playback time on pause
        playerRef.current.on('pause', () => {
          localStorage.setItem(`video-save-point-${match.params.id}-${season}-${episode}`, playerRef.current.currentTime());
        });

        playerRef.current.qualityLevels().on('addqualitylevel', () => {
          const levels = playerRef.current.qualityLevels().map((level) => level.height);

          playerRef.current.controlBar.addChild('qualitySelector', { levels });

        });


      })
      .catch((error) => {
        console.log(error);
      });




    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [match.params.type, match.params.id, season, episode]);

  if (loading){
      return (
          <div>
              Loading...
          </div>
      )
  }
  console.log(match.params.type)
      return (
          <div>
              <div className={styles.videoPlayer}>
                  <div data-vjs-player="">
                      <video class="video-js" controls preload="auto" poster={poster} ref={videoRef}
                             className={styles.videoJs}/>
                  </div>
              </div>
              <div><a href="https://alibaba-cdn.net/hls4/aWQ9MjI0NjQzNzsxMDkxOTQ0MzY4OzIzMzcyOTk4OzU1MjY1ODsxNjg2ODQ4MDAzJmg9TUs5bGtFaHFVc2VhRllrRmI1WmJfUSZlPTE2ODY5MzQ0MDM/552658.m3u8"></a></div>
              {(match.params.type === "serial" || match.params.type === "docuserial" || match.params.type === "tvshow") ? (
              <div className="row m-b">
                  <div className="season">
                      {Array.from({length: suggestions}, (_, i) => (
                      <span className="p-r-sm p-t-sm">
                      <a className={ (i+1) == season ? "btn btn-success m-b-sm" : "btn btn-outline-success m-b-sm" }
                         href={"/video/" + match.params.type + "/" + match.params.id + "/s" + (i + 1)}
                         role="button">{(i + 1)}</a>
                    </span>
                      ))}
                  </div>
                  <div className="line"></div>
                  <div className="episodes">
                      {Array.from({length: episodes}, (_, i) => (
                      <span className="p-r-sm p-t-sm">
                      <a className={ (i+1) == episode ? "btn btn-success m-b-sm" : "btn btn-outline-success m-b-sm" }
                         href={"/video/" + match.params.type + "/" + match.params.id + "/s" + season + "/e" + (i+1)}
                         role="button">{(i + 1)}</a>
                    </span>
                      ))}
                  </div>
              </div>
              ) : null}
           </div>
      );
};

export default Video;

